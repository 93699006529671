import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { ref, onValue, set, get } from 'firebase/database';
import { collection, query, where, getDocs, serverTimestamp, addDoc } from 'firebase/firestore';
import { ref as storageRef, listAll, getDownloadURL, uploadBytes } from 'firebase/storage';
import { db, storage, firestore  } from '../firebase/config';
import { Slider } from "../components/ui/slider";
import { Button } from "../components/ui/button";
// Import Lucide icons for better UI
import { ChevronLeft, ChevronRight, ImagePlus, Wand2, Loader2 } from "lucide-react";
import { useToast } from '../components/ui/toastProvider';
import HowToPlayModal from '../components/ui/howToPlayModal';

const MixerScreen = () => {
  const maxTime = 180;
  const { gameId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [deviceId] = useState(localStorage.getItem('deviceId'));
  const [timer, setTimer] = useState(location.state?.timer || maxTime);
  const [creativeControl, setCreativeControl] = useState(50);
  const [selectedImages, setSelectedImages] = useState(location.state?.selectedImages || [null, null, null]);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showHowToPlay, setShowHowToPlay] = useState(() => {
    const shown = localStorage.getItem(`howToPlayShown_${gameId}`);
    return !shown;
  });
  const [isSoloGame, setIsSoloGame] = useState(false);
  const labels = ['Art Style', 'Clothing', 'Background'];
  const folders = ['loras', 'clothes', 'background'];  // 각 레이블에 대응하는 Firebase Storage 폴더명
  const isNavigating = useRef(false);
  const startTimeRef = useRef(location.state?.startTime || Date.now());
  const initialImageLoadedRef = useRef(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const { showToast } = useToast();
  const generateLock = useRef(false);
  useEffect(() => {
    window.addEventListener('beforeunload', (event) => {
    });
  }, [isGenerating]);

  useEffect(() => {
    const gameRef = ref(db, `games/${gameId}/isSoloGame`);
    const unsubscribe = onValue(gameRef, (snapshot) => {
      if (snapshot.exists()) {
        setIsSoloGame(snapshot.val());
      }
    });
  
    return () => unsubscribe();
  }, [gameId]);

  useEffect(() => {
    const loadOrSetInitialImage = async () => {
      if (initialImageLoadedRef.current) return;
    
      // 플레이어의 이미지와 게임의 initialImage를 동시에 가져옴
      const playerImagesRef = ref(db, `games/${gameId}/players/${deviceId}/generatedImages`);
      const gameInitialImageRef = ref(db, `games/${gameId}/initialImage`);
      const generatingRef = ref(db, `games/${gameId}/players/${deviceId}/generating`);
    
      const [playerImagesSnapshot, gameInitialImageSnapshot, generatingSnapshot] = 
        await Promise.all([
          get(playerImagesRef),
          get(gameInitialImageRef),
          get(generatingRef)
        ]);
    
      if (generatingSnapshot.exists()) {
        setIsGenerating(generatingSnapshot.val());
      }
    
      // 이미 플레이어의 이미지가 있다면 그것을 사용
      if (playerImagesSnapshot.exists()) {
        const images = playerImagesSnapshot.val();
        setGeneratedImages(images);
        initialImageLoadedRef.current = true;
        return;
      }
    
      // 플레이어의 이미지가 없다면 게임의 initialImage 사용
      if (gameInitialImageSnapshot.exists()) {
        const initialImage = gameInitialImageSnapshot.val();
        const initialImages = [initialImage];
        
        // 플레이어의 generatedImages에 저장
        await set(playerImagesRef, initialImages);
        setGeneratedImages(initialImages);
        initialImageLoadedRef.current = true;
      } else {
        console.error('No initial image found for the game');
        showToast('Error: Initial image not found', 'error');
      }
    };
    

    // 실시간 업데이트 리스너 설정
    const listenToPlayerImages = () => {
      const playerImagesRef = ref(db, `games/${gameId}/players/${deviceId}/generatedImages`);
      const generatingRef = ref(db, `games/${gameId}/players/${deviceId}/generating`);
      const unsubscribeImages = onValue(playerImagesRef, (snapshot) => {
        if (snapshot.exists()) {
          const images = snapshot.val();
          setGeneratedImages(images);
        }
      });
  
      const unsubscribeGenerating = onValue(generatingRef, (snapshot) => {
        if (snapshot.exists()) {
          setIsGenerating(snapshot.val());
        }
      });
  
      return () => {
        unsubscribeImages();
        unsubscribeGenerating();
      };
    };

    loadOrSetInitialImage();
    const unsubscribe = listenToPlayerImages();

    return () => {
      unsubscribe();
    };
  }, [gameId, deviceId]);

  useEffect(() => {
    if (location.state?.selectedImageData && typeof location.state.index !== 'undefined') {
      setSelectedImages(prev => {
        const updated = [...prev];
        updated[location.state.index] = location.state.selectedImageData;
        return updated;
      });
    }
  }, [location.state]);
  // useEffect(() => {
  //   if (location.state?.selectedImage && typeof location.state.index !== 'undefined') {
  //     const updatedImages = [...selectedImages];
  //     updatedImages[location.state.index] = { imageUrl: location.state.selectedImage };
  //     setSelectedImages(updatedImages);
  //   }
  // }, [location.state]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        const elapsed = Math.floor((Date.now() - startTimeRef.current) / 1000);
        const newTime = Math.max(maxTime - elapsed, 0);
        if (newTime <= 0) {
          clearInterval(interval);
          if (!isNavigating.current) {
            isNavigating.current = true;
            navigate(`/image-submit/${gameId}`);
          }
        }
        return newTime;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [gameId, navigate]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  
  const handleImageSelect = (index) => {
    // Firebase 구독 정리 후 navigate
    const gameRef = ref(db, `games/${gameId}`);
    const unsubscribe = onValue(gameRef, () => {});
    unsubscribe();  // 즉시 구독 해제
    
    navigate(`/image-selection/${gameId}`, {
      state: { 
        theme: labels[index], 
        folder: folders[index],  // Storage 폴더명 전달
        index, 
        timer, 
        selectedImages, 
        startTime: startTimeRef.current 
      }
    });
  };

  const handleMix = async () => {
    if (generatedImages.length >= 20) {
      showToast('Maximum limit of 20 images reached', 'error');
      return;
    }
    const generatingRef = ref(db, `games/${gameId}/players/${deviceId}/generating`);
    
    // 현재 생성 중인지 확인
    const snapshot = await get(generatingRef);
    if (snapshot.exists() && snapshot.val() === true) return;
    
    // 생성 시작
    await set(generatingRef, true);
    setIsGenerating(true);

    try { 
      if (!selectedImages[0]?.id || !selectedImages[1]?.id) {
        throw new Error('Missing required model or clothing selection');
      }
      
      // 수정 후: generatedImages[0]의 resources.model을 사용
      if (!generatedImages[0]?.resources?.model || !selectedImages[1]?.id) {
        throw new Error('Missing required model or clothing selection');
      }
      
      const vtonImagesRef = collection(firestore, 'vtonImages');
      const q = query(
        vtonImagesRef,
        where('resources.model', '==', generatedImages[0].resources.model), // generatedImages[0]의 model ID 사용
        where('resources.clothes', '==', selectedImages[1].id)
      );
  
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        throw new Error('No matching combined image found');
      }
      const vtonImageData = querySnapshot.docs[0].data();
    
      // lora와 background의 prompt 가져오기
      const loraPrompt = selectedImages[0]?.prompt;
      const backgroundPrompt = selectedImages[2]?.prompt;
      
      // 프롬프트 조합
      const combinedPrompt = [loraPrompt, vtonImageData?.prompt, backgroundPrompt]
        .join('');

  
      // API 요청 데이터
      const requestData = {
        model: generatedImages[0].imageUrl,
        clothing: selectedImages[1]?.imageUrl || null,
        vtonImage: vtonImageData.imageUrl || null, 
        background: selectedImages[2]?.imageUrl || null,
        strength: creativeControl,
        prompt: combinedPrompt,
        lora:`${selectedImages[0]?.fileName}.safetensors`,
        api_key: process.env.REACT_APP_MODAL_API_KEY || ""
      };

      const response = await fetch(process.env.REACT_APP_API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData)
      });

      if (!response.ok) {
        throw new Error(`API request failed: ${response.statusText}`);
      }

      // 받은 이미지 데이터를 Blob으로 변환

      const imageBlob = await response.blob();
      const timestamp = Date.now();
      const filename = `mixed_images/${gameId}/${deviceId}/${timestamp}.jpg`;

      const imageRef = storageRef(storage, filename);
      await uploadBytes(imageRef, imageBlob);
      const imageUrl = await getDownloadURL(imageRef);

      const mixLogRef = collection(firestore, 'mixLogs');
      const mixLogData = {
        // 기본 정보
        gameId,
        deviceId,
        timestamp: serverTimestamp(),
        imageUrl,
        
        // 소스 이미지 정보
        sourceImages: {
          baseModel: generatedImages[0]?.imageUrl || null,
          artStyle: selectedImages[0]?.imageUrl || null,
          clothing: selectedImages[1]?.imageUrl || null,
          background: selectedImages[2]?.imageUrl || null,
        },
        
        // 믹스 설정
        mixSettings: {
          creativeControl,
          prompt: combinedPrompt,
          lora: requestData.lora
        },
        
        // 성능 메트릭 (선택적)
        metrics: {
          generationTime: Date.now() - timestamp,
          success: true
        },

        // 게임 컨텍스트
        gameContext: {
          remainingTime: timer,
          mixCount: generatedImages.length + 1
        }
      };
      await addDoc(mixLogRef, mixLogData);

      // 생성된 이미지 정보 생성
      const mixInfo = {
        type: 'mix',
        strength: creativeControl,
        prompt: combinedPrompt,
        lora: requestData.lora,
        timestamp,
        storageRef: filename,
        sourceImages: {
          baseModel: generatedImages[0]?.imageUrl || null,
          artStyle: selectedImages[0]?.imageUrl || null,
          clothing: selectedImages[1]?.imageUrl || null,
          background: selectedImages[2]?.imageUrl || null
        }
      };

      const newGeneratedImage = {
        imageUrl,
        mixInfo
      };

      // Firebase에 새로운 이미지 저장
      const playerImagesRef = ref(db, `games/${gameId}/players/${deviceId}/generatedImages`);
      const currentImages = [...generatedImages, newGeneratedImage];
      await set(playerImagesRef, currentImages);

      // 로컬 상태 업데이트
      setGeneratedImages(currentImages);
      setCurrentImageIndex(currentImages.length - 1);  // 새로 생성된 이미지로 이동
      await set(generatingRef, false);
      showToast('Image generated successfully! Check your gallery', 'success');

    } catch (error) {
      console.error('Error mixing images:', error);
      await set(generatingRef, false);
      showToast('Failed to generate image. Please try again.', 'error');
      // 에러 처리 로직 추가
      const mixLogRef = collection(firestore, 'mixLogs');
      await addDoc(mixLogRef, {
        gameId,
        deviceId,
        timestamp: serverTimestamp(),
        error: error.message,
        status: 'failed',
        // ... 다른 관련 정보들
      });
    } finally {
      setTimeout(() => {
        generateLock.current = false;
      }, 1000); // Prevent rapid re-clicks
    }
  };

  const handleNavigateImage = (direction) => {
    if (direction === 'left') {
      setCurrentImageIndex(prev => 
        prev === 0 ? generatedImages.length - 1 : prev - 1
      );
    } else if (direction === 'right') {
      setCurrentImageIndex(prev => 
        prev === generatedImages.length - 1 ? 0 : prev + 1
      );
    }
  };

  const handleCloseModal = () => {
    setShowHowToPlay(false);
    localStorage.setItem(`howToPlayShown_${gameId}`, 'true');
  };

  return (
    // 배경색을 더 밝게 조정하고 패딩 줄임
    <div className="min-h-screen bg-white py-4 px-4">
      <HowToPlayModal isOpen={showHowToPlay && isSoloGame} onClose={handleCloseModal}  />
      <div className="max-w-6xl mx-auto space-y-4">
        {/* 타이머 섹션 크기 축소 */}

        <div className="text-center mb-2">
        {generatedImages[0]?.mixInfo?.prompt && (
            <div className="text-2xl font-medium text-purple-600 mb-1">
              Aesthetic: {generatedImages[0]?.theme || "boss"}
            </div>
          )}
          <div className="text-xl font-mono text-purple-600 mb-1">{formatTime(timer)}</div>
          {!isSoloGame && (<div className="text-sm font-medium text-gray-900">..until voting begins</div>)}
          <div className="w-full max-w-[200px] mx-auto h-[2px] bg-gradient-to-r from-transparent via-purple-100 to-transparent mt-3" />
        </div>

        {/* 결과 이미지 캐러셀 - 더 큰 크기로 조정 */}
        <h2 className="text-lg font-semibold text-gray-900">Base & Your Creations</h2>
        <div className="flex items-center justify-between gap-2">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => handleNavigateImage('left')}
            className="h-8 w-8 shrink-0"
          >
            <ChevronLeft className="h-6 w-6" />
          </Button>

          {/* 이미지 컨테이너를 더 크게 */}
          <div className="relative flex-1 aspect-[512/768] bg-white rounded-lg overflow-hidden border border-gray-100 shadow-sm">
            <div className="absolute inset-0 flex items-center justify-center">
            {generatedImages[currentImageIndex] ? (
                <img
                  src={generatedImages[currentImageIndex].imageUrl}
                  alt={`Generated ${currentImageIndex + 1}`}
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="text-base text-gray-500">
                  No image generated
                </div>
              )}
            </div>
          </div>

          <Button
            variant="ghost"
            size="icon"
            onClick={() => handleNavigateImage('right')}
            className="h-8 w-8 shrink-0"
          >
            <ChevronRight className="h-6 w-6" />
          </Button>
        </div>

        <div className="space-y-1">
          <h2 className="text-lg font-semibold text-gray-900">Mix Ingredients</h2>
        </div>

        {/* 이미지 선택 그리드 - 더 큰 이미지로 조정 */}
        <div className="grid grid-cols-3 gap-3">
          {labels.map((value, index) => (
            <div key={index} className="space-y-2">
              {/* 레이블 크기 축소 */}
              <div className="text-sm font-medium text-gray-700 text-center">
                {labels[index]}
              </div>
              
              {/* 이미지 선택 영역 - 카드 제거하고 직접 스타일링 */}
              <div
                onClick={() => handleImageSelect(index)}
                className="relative aspect-[512/768] rounded-lg overflow-hidden group cursor-pointer
                         transition-transform duration-300 hover:scale-[1.02] shadow-sm border border-gray-200"
              >
                {selectedImages[index]?.imageUrl ? (
                  <>
                    <img
                      src={selectedImages[index].imageUrl}
                      alt={labels[index]}
                      className="w-full h-full object-cover"
                    />
                    {/* 호버 오버레이 */}
                    <div className="absolute inset-0 bg-black bg-opacity-40 opacity-0 group-hover:opacity-100
                                  flex items-center justify-center transition-opacity duration-300">
                      <ImagePlus className="w-8 h-8 text-white" />
                    </div>
                  </>
                ) : (
                  <div className="w-full h-full bg-gray-100 flex flex-col items-center justify-center
                                group-hover:bg-gray-200 transition-colors duration-300">
                    <ImagePlus className="w-8 h-8 text-gray-400 mb-1" />
                    <span className="text-sm text-gray-500">Select {labels[index]}</span>
                  </div>
                )}
              </div>

              {/* 슬라이더 여백 조정 */}
            </div>
          ))}
        </div>
        <div className="space-y-3 pt-4">
          <div>
            <h2 className="text-lg font-semibold text-gray-900">Creative Control</h2>
            <p className="text-sm text-gray-500">Higher = further from original images</p>
          </div>
          <div className="px-4">
            <Slider
              value={[creativeControl]}
              onValueChange={([value]) => setCreativeControl(value)}
              max={100}
              step={1}
              className="w-full"
            />
          </div>
        </div>
        

        {/* Mix 버튼 - 높이 축소 */}
        <Button
  onClick={handleMix}
  disabled={isGenerating}
  className={`w-full h-12 text-base ${
    isGenerating 
      ? 'bg-gray-100 text-gray-700 cursor-not-allowed' 
      : 'bg-gradient-to-r from-purple-600 to-blue-600 hover:from-purple-700 hover:to-blue-700'
  } shadow-md transform transition-all duration-300 hover:scale-[1.01] hover:shadow-lg mt-4`}
>
  {isGenerating ? (
    <>
      <Loader2 className="w-5 h-5 mr-2 animate-spin" />
      <div className="flex flex-col items-start">
        <span>Generating... (15-25s)</span>
        <span className="text-xs opacity-75">Don't like the result? Try again!</span>
      </div>
    </>
  ) : (
    <>
      <Wand2 className="w-5 h-5 mr-2" />
      Mix Images
    </>
  )}
</Button>
      </div>
    </div>
  );
};

export default MixerScreen;
