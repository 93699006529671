import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ref, push, set, get, serverTimestamp } from 'firebase/database';
import { db } from '../firebase/config';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../firebase/config';
import { generateDeviceId } from '../firebase/gameLogic';

const FriendsGameScreen = () => {
  const navigate = useNavigate();
  const [roomCode, setRoomCode] = useState('');
  const [showJoinForm, setShowJoinForm] = useState(false);
  const [error, setError] = useState('');
  
  const generateRoomCode = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    return Array(5).fill(0).map(() => chars[Math.floor(Math.random() * chars.length)]).join('');
  };

  const createRoom = async () => {
    const deviceId = localStorage.getItem('deviceId') || generateDeviceId();
    localStorage.setItem('deviceId', deviceId);
    
    const modelCollectionRef = collection(firestore, 'model');
    const querySnapshot = await getDocs(modelCollectionRef);
    
    if (querySnapshot.empty) {
      setError('No models available');
      return;
    }

    const documents = querySnapshot.docs;
    const randomIndex = Math.floor(Math.random() * documents.length);
    const randomDoc = documents[randomIndex];
    const docData = randomDoc.data();

    const initialImage = {
      imageUrl: docData.imageUrl,
      filename: docData.fileName,
      theme: docData.theme,
      mixInfo: { 
        type: 'initial',
        filename: docData.fileName,
        prompt: docData.prompt 
      },
      resources: {
        model: randomDoc.id
      }
    };

    const roomCode = generateRoomCode();
    const newGameRef = push(ref(db, 'games'));
    console.log('Creating room with code:', roomCode);
    console.log('Game ref:', newGameRef.key);
    
    await set(newGameRef, {
      status: 'waiting',
      roomCode,
      isPrivate: true,
      createdAt: serverTimestamp(),
      initialImage,
      players: {
        [deviceId]: { ready: false, joinedAt: serverTimestamp() }
      }
    });
    fetch(process.env.REACT_APP_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ check_type: 'container_status' })
    }).catch(console.error);
    const checkRef = ref(db, `games/${newGameRef.key}`);
    const snapshot = await get(checkRef);
    console.log('Saved game data:', snapshot.val());


    navigate(`/waiting-room/${newGameRef.key}`, { 
      state: { roomCode, isHost: true }
    });
  };

  const joinRoom = async () => {
    console.log(roomCode, roomCode.length, 'edc')
    if (!roomCode || roomCode.length !== 5) {
      setError('Please enter a valid 5-character room code');
      return;
    }

    const gamesRef = ref(db, 'games');
    const snapshot = await get(gamesRef);
    const games = snapshot.val() || {};
    console.log('Available games:', games);  // 디버깅용
    const upperRoomCode = roomCode.toUpperCase();
    const gameEntry = Object.entries(games).find(([_, game]) => {
      console.log('Checking game:', game.roomCode, 'against:', upperRoomCode); // 디버깅용
      return game.roomCode === upperRoomCode && game.status === 'waiting';
    });

    // const gameEntry = Object.entries(games).find(([_, game]) => 
      
    //   game.roomCode === roomCode.toUpperCase() && game.status === 'waiting'
    // );

    if (!gameEntry) {
      setError('Room not found or game already started');
      return;
    }

    const [gameId, gameData] = gameEntry;
    
    if (Object.keys(gameData.players || {}).length >= 5) {
      setError('Room is full');
      return;
    }

    const deviceId = localStorage.getItem('deviceId') || generateDeviceId();
    localStorage.setItem('deviceId', deviceId);

    await set(ref(db, `games/${gameId}/players/${deviceId}`), {
      ready: false,
      joinedAt: serverTimestamp()
    });

    navigate(`/waiting-room/${gameId}`);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-md mx-auto space-y-6">
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-8">
          Play with Friends
        </h2>
        
        {!showJoinForm ? (
          <div className="space-y-4">
            <button
              onClick={createRoom}
              className="menu-button w-full py-4 text-gray-800 rounded-lg font-bold tracking-wider text-sm uppercase transition-all duration-300 hover:scale-105"

            >
              Make a Room
            </button>
            
            <button
              onClick={() => setShowJoinForm(true)}
              className="menu-button w-full py-4 text-gray-800 rounded-lg font-bold tracking-wider text-sm uppercase transition-all duration-300 hover:scale-105"

            >
              Find a Room
            </button>
          </div>
        ) : (
          <div className="space-y-4">
            <input
              type="text"
              value={roomCode}
              onChange={(e) => setRoomCode(e.target.value.toUpperCase())}
              maxLength={5}
              placeholder="Enter 5-character room code"
              className="w-full p-4 border rounded-lg text-center uppercase"
            />
            
            {error && (
              <div className="text-red-500 text-center text-sm">
                {error}
              </div>
            )}
            
            <button
              onClick={joinRoom}
              className="menu-button w-full py-4 text-gray-800 rounded-lg font-bold tracking-wider text-sm uppercase transition-all duration-300 hover:scale-105"

            >
              Join Room
            </button>
            
            <button
              onClick={() => {
                setShowJoinForm(false);
                setError('');
                setRoomCode('');
              }}
              className="menu-button w-full py-4 bg-gray-500 text-white rounded-lg font-bold shadow-md"
            >
              Back
            </button>
          </div>
        )}
        <button
        onClick={() => navigate('/')}
        className="menu-button w-full py-4 bg-gray-500 text-white rounded-lg font-bold shadow-md"
        >
        Back to Main Menu
        </button>
      </div>
    </div>
  );
};

export default FriendsGameScreen;