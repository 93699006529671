import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ref, onValue, set, get } from 'firebase/database';
import { db, firestore } from '../firebase/config';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { Star, ChevronLeft, ChevronRight } from "lucide-react";

const RatingScreen = () => {
  const { gameId } = useParams();
  const navigate = useNavigate();
  const [timer, setTimer] = useState(null);
  const [players, setPlayers] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [ratings, setRatings] = useState({});
  const [deviceId] = useState(localStorage.getItem('deviceId'));
  const isNavigating = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const timerRef = useRef(null);
  const intervalRef = useRef(null);

  const saveTimerStart = async () => {
    const timerRef = ref(db, `games/${gameId}/timer`);
    const totalTime = players.length * 10;
    
    const snapshot = await get(timerRef);
    if (!snapshot.exists()) {
      await set(timerRef, {
        startTime: {
          '.sv': 'timestamp'
        },
        totalTime: totalTime
      });
    }
  };

  useEffect(() => {
    if (!players.length) return;

    const dbRef = ref(db, `games/${gameId}/timer`);
    const unsubscribe = onValue(dbRef, async (snapshot) => {
      const data = snapshot.val();
      
      if (!data) {
        await saveTimerStart();
        return;
      }

      // 기존 인터벌 정리
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      // 새로운 타이머 인터벌 설정
      intervalRef.current = setInterval(() => {
        const now = Date.now();
        const elapsed = Math.floor((now - data.startTime) / 1000);
        const remaining = Math.max(0, data.totalTime - elapsed);
        setTimer(remaining);
      }, 1000);

      // 초기값 설정
      const elapsed = Math.floor((Date.now() - data.startTime) / 1000);
      const remaining = Math.max(0, data.totalTime - elapsed);
      setTimer(remaining);
    });

    return () => {
      unsubscribe();
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [players, gameId]);

  // 타이머가 0이 되었을 때 처리
  useEffect(() => {
    if (timer === 0 && !isNavigating.current) {
      isNavigating.current = true;
      saveRatings(true);
      navigate(`/result/${gameId}`);
    }
  }, [timer, gameId, navigate]);

  useEffect(() => {
    const gameRef = ref(db, `games/${gameId}`);
    let initialLoad = true;

    const unsubscribe = onValue(gameRef, async (snapshot) => {
      const game = snapshot.val();
      if (!game || !game.players) return;

      const otherPlayers = Object.entries(game.players)
        .filter(([playerId]) => playerId !== deviceId)
        .filter(([_, player]) => player.submittedImage);

      if (initialLoad) {
        const expectedPlayerCount = Object.keys(game.players).length - 1;
        
        if (otherPlayers.length === expectedPlayerCount) {
          const playerData = otherPlayers.map(([playerId, player]) => ({
            id: playerId,
            imageUrl: player.submittedImage.imageUrl,
            mixInfo: player.submittedImage.mixInfo
          }));
          
          setPlayers(playerData);
          setIsLoading(false);
          initialLoad = false;
        }
      }
    });

    return () => unsubscribe();
  }, [gameId, deviceId]);

  const saveRatings = async (isTimeout = false) => {
    try {
      await Promise.all([
        set(ref(db, `games/${gameId}/ratings/${deviceId}`), ratings),
        addDoc(collection(firestore, 'voteLogs'), {
          gameId,
          voterId: deviceId,
          timestamp: serverTimestamp(),
          ratings: ratings,
          votingTime: players.length * 8 - (timer || 0),
          isTimeout,
          voted: true
        })
      ]);
    } catch (error) {
      console.error('Error saving votes:', error);
    }
  };

  const handleRating = async (playerId, rating) => {
    const newRatings = {
      ...ratings,
      [playerId]: rating
    };
    setRatings(newRatings);
    
    try {
      await set(ref(db, `games/${gameId}/ratings/${deviceId}`), newRatings);
    } catch (error) {
      console.error('Error saving rating:', error);
    }
  };

  const navigateToResult = () => {
    if (!isNavigating.current) {
      isNavigating.current = true;
      navigate(`/result/${gameId}`);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const nextImage = () => {
    setCurrentIndex((prev) => (prev + 1) % players.length);
  };

  const prevImage = () => {
    setCurrentIndex((prev) => (prev - 1 + players.length) % players.length);
  };

  const StarRating = ({ rating, onRate }) => {
    return (
      <div className="flex gap-2 justify-center">
        {[1, 2, 3, 4, 5].map((star) => (
          <button
            key={star}
            onClick={() => onRate(star)}
            className="focus:outline-none"
          >
            <Star
              className={`w-8 h-8 ${
                star <= rating
                  ? 'fill-yellow-400 text-yellow-400'
                  : 'text-gray-300'
              }`}
            />
          </button>
        ))}
      </div>
    );
  };

  if (players.length === 0) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p className="text-gray-500">Waiting for other players...</p>
      </div>
    );
  }

  const currentPlayer = players[currentIndex];

  return (
    <div className="min-h-screen bg-white py-4 px-4">
      <div className="max-w-4xl mx-auto space-y-4">
        <div className="text-center mb-2">
          <div className="text-lg font-medium text-gray-900">Voting ends in</div>
          <div className="text-xl font-mono text-purple-600 mb-1">{formatTime(timer)}</div>
          <div className="w-full max-w-[200px] mx-auto h-[2px] bg-gradient-to-r from-transparent via-purple-100 to-transparent mt-3" />
        </div>

        <div className="relative">
          <div className="aspect-[512/768] rounded-lg overflow-hidden border border-gray-200 shadow-sm">
            <img
              src={currentPlayer.imageUrl}
              alt="Player submission"
              className="w-full h-full object-cover"
            />
          </div>
          
          <button
            onClick={prevImage}
            className="absolute left-4 top-1/2 -translate-y-1/2 bg-white/80 p-2 rounded-full shadow-lg hover:bg-white"
          >
            <ChevronLeft className="w-6 h-6" />
          </button>
          
          <button
            onClick={nextImage}
            className="absolute right-4 top-1/2 -translate-y-1/2 bg-white/80 p-2 rounded-full shadow-lg hover:bg-white"
          >
            <ChevronRight className="w-6 h-6" />
          </button>
        </div>

        <div className="text-center">
          <p className="text-sm text-gray-500 mb-2">
            Image {currentIndex + 1} of {players.length}
          </p>
          <StarRating
            rating={ratings[currentPlayer.id] || 0}
            onRate={(rating) => handleRating(currentPlayer.id, rating)}
          />
        </div>
      </div>
    </div>
  );
};

export default RatingScreen;