import React, { useState, useEffect } from 'react';
import { ref, onValue, set, push, serverTimestamp } from 'firebase/database';
import { db } from '../firebase/config';
import { Filter } from 'bad-words';
import frontScreenImage from '../images/frontScreen.png';


const filter = new Filter();

const RoomListScreen = ({ deviceId, onRoomSelect }) => {
  const [rooms, setRooms] = useState([]);
  const [newRoomTitle, setNewRoomTitle] = useState('');
  const [showCreateForm, setShowCreateForm] = useState(false);
  
  useEffect(() => {
    const gamesRef = ref(db, 'games');
    return onValue(gamesRef, (snapshot) => {
      const games = snapshot.val() || {};
      const waitingRooms = Object.entries(games)
        .filter(([_, game]) => game.status === 'waiting' && 
        Object.keys(game.players || {}).length > 0 && // 플레이어 0명인 방 제외
        Object.keys(game.players || {}).length < 5)
        .map(([id, game]) => ({
          id,
          title: game.title,
          playerCount: Object.keys(game.players || {}).length,
          readyCount: Object.values(game.players || {}).filter(p => p.ready).length
        }))
        .sort((a, b) => b.readyCount - a.readyCount);
      setRooms(waitingRooms);
    });
  }, []);

  const createRoom = async (e) => {
    e.preventDefault();
    const title = newRoomTitle.trim();
    if (!title) return;
    
    // 욕설 체크
    try {
      if (filter.isProfane(title)) {
        alert('Inappropriate words are not allowed in room title');
        return;
      }
    } catch (error) {
      console.error('Profanity check error:', error);
    }

    const newGameRef = push(ref(db, 'games'));

    fetch(process.env.REACT_APP_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ check_type: 'container_status' })
    }).catch(console.error); // 에러가 나도 계속 진행

    await set(newGameRef, {
      status: 'waiting',
      title: title.slice(0, 20),
      createdAt: serverTimestamp(),
      players: {
        [deviceId]: { ready: false, joinedAt: serverTimestamp() }
      }
    });
    onRoomSelect(newGameRef.key);
  };

  const joinRoom = async (roomId) => {
    const playerRef = ref(db, `games/${roomId}/players/${deviceId}`);
    await set(playerRef, {
      ready: false,
      joinedAt: serverTimestamp()
    });
    onRoomSelect(roomId);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex flex-col justify-center mb-6">
        <div className="text-center mb-3">
        Welcome, have fun! ☺️
        </div>
        <img 
          src={frontScreenImage} 
          alt="Front Screen Fashion"
          className="w-full max-w-3xl rounded-lg shadow-lg py-4"
        />
      </div>
      <div className="space-y-4 mb-8">
      <h2 className="text-xl font-bold text-center">
        Join or create a room! 
      </h2>
      <h3 className="text-sm font-bold text-center mb-6">
      (3 ~ 5 players per game)
      </h3>
      {rooms.map(room => (
          <button
            key={room.id}
            onClick={() => joinRoom(room.id)}
            className="w-full p-4 border rounded text-left hover:bg-gray-50"
          >
            <div className="font-medium">{room.title}</div>
            <div className="text-sm text-gray-500">
              Players: {room.playerCount} • Ready: {room.readyCount}
            </div>
          </button>
        ))}
      {!showCreateForm ? (
        <button
          onClick={() => setShowCreateForm(true)}
          className="w-full py-4 bg-blue-500 text-white rounded-lg font-bold"
        >
          Create Room
        </button>
      ) : (
        <form onSubmit={createRoom} className="space-y-4">
          <input
            type="text"
            value={newRoomTitle}
            onChange={(e) => setNewRoomTitle(e.target.value)}
            maxLength={20}
            placeholder="Enter Room Name (~20 characters)"
            className="w-full p-4 border rounded"
            required
          />
          <div className="flex space-x-4">
            <button
              type="submit"
              className="flex-1 py-4 bg-blue-500 text-white rounded-lg font-bold"
            >
              Create
            </button>
            <button
              type="button"
              onClick={() => setShowCreateForm(false)}
              className="flex-1 py-4 bg-gray-500 text-white rounded-lg font-bold"
            >
              Cancel
            </button>
          </div>
        </form>
      )}
      </div>
    </div>
  );
};

export default RoomListScreen;