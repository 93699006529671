import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ref as dbRef, set, onValue, get } from 'firebase/database';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db, firestore, storage } from '../firebase/config';
import { Button } from "../components/ui/button";
import { ImageModal } from "../components/ui/imageModal";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { ref as storageRef, getDownloadURL } from 'firebase/storage';

const ImageSubmitScreen = () => {
  const { gameId } = useParams();
  const navigate = useNavigate();
  const [deviceId] = useState(localStorage.getItem('deviceId'));
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [actualTimer, setActualTimer] = useState(30); // 실제 타이머
  const [displayTimer, setDisplayTimer] = useState(27); // 표시되는 타이머
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [isSoloGame, setIsSoloGame] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const [modalImage, setModalImage] = useState(null);
  const isNavigating = useRef(false);
  
  const AUTO_SUBMIT_THRESHOLD = 3;

  useEffect(() => {
    const gameRef = dbRef(db, `games/${gameId}/isSoloGame`);
    const unsubscribe = onValue(gameRef, (snapshot) => {
      if (snapshot.exists()) {
        setIsSoloGame(snapshot.val());
      }
    });
  
    return () => unsubscribe();
  }, [gameId]);

  useEffect(() => {
    const playerRef = dbRef(db, `games/${gameId}/players/${deviceId}/generatedImages`);
    const unsubscribe = onValue(playerRef, (snapshot) => {
      const images = snapshot.val();
      if (images) {
        setGeneratedImages(images);
      }
    });

    return () => unsubscribe();
  }, [gameId, deviceId]);

  useEffect(() => {
    if (isSoloGame) return;
    let timerInterval;
    timerInterval = setInterval(() => {
      setActualTimer((prevTimer) => {
        // 실제 타이머 처리
        if (prevTimer === AUTO_SUBMIT_THRESHOLD && !isSubmitted) {
          handleSubmit(true);
        }
        if (prevTimer <= 0) {
          clearInterval(timerInterval);
          handleTimeout();
          return 0;
        }
        return prevTimer - 1;
      });

      setDisplayTimer((prevDisplay) => {
        // 표시 타이머는 3초 일찍 0에 도달
        return Math.max(0, prevDisplay - 1);
      });
    }, 1000);

    return () => {
      if (timerInterval) clearInterval(timerInterval);
    };
  }, [isSoloGame]);

  const createSubmissionLog = async (selectedImage, isAutoSubmit = false) => {
    const defaultMixInfo = {
      strength: 0.7,
      timestamp: Date.now()
    };

    const submissionLogRef = collection(firestore, 'submissionLogs');
    await addDoc(submissionLogRef, {
      gameId,
      deviceId,
      timestamp: serverTimestamp(),
      submittedImage: {
        imageUrl: selectedImage.imageUrl,
        creativeStrength: selectedImage.mixInfo?.strength || defaultMixInfo.strength,
        generationTimestamp: selectedImage.mixInfo?.timestamp || defaultMixInfo.timestamp
      },
      totalImagesGenerated: generatedImages.length,
      selectedImageIndex: currentImageIndex,
      isAutoSubmit
    });
  };

  const handleNavigateImage = (direction) => {
    if (isSubmitted) return;
    setCurrentImageIndex((prevIndex) => {
      if (direction === 'left' && prevIndex > 0) {
        return prevIndex - 1;
      } else if (direction === 'right' && prevIndex < generatedImages.length - 1) {
        return prevIndex + 1;
      }
      return prevIndex;
    });
  };

  const handleSubmit = async (isAuto = false) => {
    if (isSubmitted) return;
    
    setIsSubmitted(true);
    const selectedImage = generatedImages[currentImageIndex];
    
    if (selectedImage) {
      try {
        const submittedImageData = {
          imageUrl: selectedImage.imageUrl,
          mixInfo: {
            ...selectedImage.mixInfo,
            sourceImages: selectedImage.mixInfo?.sourceImages || null
          } || { strength: 0.7, timestamp: Date.now() }
        };

        await set(dbRef(db, `games/${gameId}/players/${deviceId}/submittedImage`), submittedImageData);
        await set(dbRef(db, `games/${gameId}/players/${deviceId}/hasSubmitted`), true);
        await createSubmissionLog(selectedImage, isAuto);

      } catch (error) {
        console.error('Error submitting image:', error);
        setIsSubmitted(false);
      }
    }
  };

  const handleTimeout = async () => {
    if (!isNavigating.current) {
      isNavigating.current = true;
      
      try {
        // 제출된 이미지가 있는지 직접 확인
        const submittedImageRef = dbRef(db, `games/${gameId}/players/${deviceId}/submittedImage`);
        const snapshot = await get(submittedImageRef);
        
        if (!snapshot.exists()) {  // 제출된 이미지가 없는 경우에만
          if (generatedImages.length === 0) {
            const gameInitialImageRef = dbRef(db, `games/${gameId}/initialImage`);
            const initialSnapshot = await get(gameInitialImageRef);
            
            if (initialSnapshot.exists()) {
              const initialImage = initialSnapshot.val();
              await set(dbRef(db, `games/${gameId}/players/${deviceId}/submittedImage`), initialImage);
              await set(dbRef(db, `games/${gameId}/players/${deviceId}/hasSubmitted`), true);
              await createSubmissionLog(initialImage, true);
            }
          } else {
            await handleSubmit(true);
          }
        } else {
          console.log('Image already submitted');
        }
        
        navigate(`/rating/${gameId}`);
      } catch (error) {
        console.error('Error in timeout handling:', error);
        isNavigating.current = false;  // 에러 발생 시 네비게이션 플래그 리셋
      }
    }
  };

  const loadImage = async (imagePath) => {
    try {
      // Firebase Storage 경로로 부터 다운로드 URL을 가져옴
      const imageRef = storageRef(storage, imagePath);
      const url = await getDownloadURL(imageRef);
      
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "anonymous";  // crossOrigin 설정 추가
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = url;
      });
    } catch (error) {
      console.error('Error loading image:', error);
      throw error;
    }
  };

  const createCompositeImage = async (submittedImagePath, sourceImagesPaths) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
  
    const aspectRatio = 768/512;
  const mainImageWidth = 1024;
  const mainImageHeight = mainImageWidth * aspectRatio; // 768
  const sideImagesWidth = mainImageWidth / 3; // 170.67 (512/3)
  const canvasWidth = mainImageWidth + sideImagesWidth;
    
    canvas.width = canvasWidth;
    canvas.height = mainImageHeight;
  
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvasWidth, mainImageHeight);
  
    try {
      // 메인 이미지
      const mainImg = await loadImage(submittedImagePath);
      ctx.drawImage(mainImg, 0, 0, mainImageWidth, mainImageHeight);

  
      // 소스 이미지들 설정
      const sourceImagesArray = [
        sourceImagesPaths.clothing,
        sourceImagesPaths.baseModel,
        sourceImagesPaths.background
      ];
  
      const sourceImageHeight = mainImageHeight / 3;
      
      // 소스 이미지들 그리기
      for (let i = 0; i < sourceImagesArray.length; i++) {
        const img = await loadImage(sourceImagesArray[i]);
        const y = i * sourceImageHeight;
        
        ctx.drawImage(
          img,
          mainImageWidth,
          y,
          sideImagesWidth,
          sourceImageHeight
        );
      }
  
      return new Promise((resolve, reject) => {
        try {
          canvas.toBlob(blob => {
            if (blob) {
              resolve(URL.createObjectURL(blob));
            } else {
              reject(new Error('Failed to create blob'));
            }
          }, 'image/jpeg', 0.95);
        } catch (error) {
          reject(error);
        }
      });
    } catch (error) {
      console.error('Error creating composite image:', error);
      throw error;
    }
  };

  const handleSaveImage = async () => {
    try {
      const selectedImage = generatedImages[currentImageIndex];
      if (!selectedImage) {
        console.error('No image selected');
        return;
      }
  
      // Firebase Storage 경로에서 이미지 URL 가져오기
      const mainImageUrl = selectedImage.imageUrl;
          
      // sourceImages의 URL들도 Firebase Storage URL로 변환
      const sourceImagesUrls = {
        clothing: selectedImage.mixInfo.sourceImages.clothing,
        baseModel: selectedImage.mixInfo.sourceImages.baseModel,
        background: selectedImage.mixInfo.sourceImages.background
      };
  
      const compositeImageUrl = await createCompositeImage(
        mainImageUrl,
        sourceImagesUrls
      );
  
      if (isMobile) {
        setModalImage(compositeImageUrl);
      } else {
        const link = document.createElement('a');
        link.href = compositeImageUrl;
        link.download = 'my-creation-with-sources.jpg';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(compositeImageUrl);
      }
    } catch (error) {
      console.error('Error saving image:', error);
    }
  };

  // const handleTimeout = () => {
  //   if (!isNavigating.current) {
  //     isNavigating.current = true;
  //     navigate(`/rating/${gameId}`);
  //   }
  // };

  const handleFeedbackSubmit = async (e) => {
    e.preventDefault();
    if (!feedback.trim()) return;
    
    setIsSubmitting(true);
    try {
      const feedbacksRef = collection(firestore, 'feedbacks');
      await addDoc(feedbacksRef, {
        gameId,
        feedback: feedback.trim(),
        deviceId,
        timestamp: serverTimestamp()
      });
      
      setFeedback('');
      // Optional: Show success message
      alert('Thank you for your feedback!');
    } catch (error) {
      console.error('Error submitting feedback:', error);
      alert('Failed to submit feedback. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-white py-4 px-4">
      <div className="max-w-6xl mx-auto space-y-4">
      {!isSoloGame && (
        <div className="text-center mb-2">
          {displayTimer > 0 ? (
            <>
              <div className="text-lg font-medium text-gray-900">Submission ends in</div>
              <div className="text-xl font-mono text-purple-600 mb-1">{displayTimer}s</div>
            </>
          ) : (
            <>
              <div className="text-lg font-medium text-gray-900">Getting results soon...</div>
              <div className="text-base text-purple-600 animate-pulse">Please wait</div>
            </>
          )}
          <div className="w-full max-w-[200px] mx-auto h-[2px] bg-gradient-to-r from-transparent via-purple-100 to-transparent mt-3" />
        </div>
      )}
        {/* <div className="text-center mb-2">
          {displayTimer > 0 ? (
            <>
              <div className="text-lg font-medium text-gray-900">Submission ends in</div>
              <div className="text-xl font-mono text-purple-600 mb-1">{displayTimer}s</div>
            </>
          ) : (
            <>
              <div className="text-lg font-medium text-gray-900">Getting results soon...</div>
              <div className="text-base text-purple-600 animate-pulse">Please wait</div>
            </>
          )}
          <div className="w-full max-w-[200px] mx-auto h-[2px] bg-gradient-to-r from-transparent via-purple-100 to-transparent mt-3" />
        </div> */}

        <div className="flex items-center justify-between gap-2">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => handleNavigateImage('left')}
            disabled={isSubmitted || currentImageIndex === 0}
            className="h-8 w-8 shrink-0"
          >
            <ChevronLeft className="h-6 w-6" />
          </Button>

          <div className="relative flex-1 aspect-[512/768] bg-white rounded-lg overflow-hidden border border-gray-100 shadow-sm">
            <div className="absolute inset-0 flex items-center justify-center">
              {generatedImages[currentImageIndex] ? (
                <img
                  src={generatedImages[currentImageIndex].imageUrl}
                  alt={`Generated ${currentImageIndex + 1}`}
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="text-base text-gray-500">
                  No image generated
                </div>
              )}
            </div>
          </div>

          <Button
            variant="ghost"
            size="icon"
            onClick={() => handleNavigateImage('right')}
            disabled={isSubmitted || currentImageIndex === generatedImages.length - 1}
            className="h-8 w-8 shrink-0"
          >
            <ChevronRight className="h-6 w-6" />
          </Button>
        </div>
        {/* 버튼 섹션 - 솔로 게임과 일반 게임에 따라 다르게 표시 */}
      {isSoloGame ? (
        <div className="flex gap-2">
          <Button
            onClick={handleSaveImage}
            className="flex-1 bg-gradient-to-r from-purple-600 to-blue-600 hover:from-purple-700 hover:to-blue-700"
          >
            Save Image
          </Button>
          <Button
            onClick={() => navigate('/')}
            variant="outline"
            className="flex-1"
          >
            Back to Menu
          </Button>
        </div>
      ) : (
        <Button
          onClick={() => handleSubmit(false)}
          disabled={isSubmitted || displayTimer === 0}
          className={`w-full h-12 text-base ${
            isSubmitted || displayTimer === 0
              ? 'bg-gray-100 text-gray-700 cursor-not-allowed' 
              : 'bg-gradient-to-r from-purple-600 to-blue-600 hover:from-purple-700 hover:to-blue-700'
          } shadow-md transform transition-all duration-300 hover:scale-[1.01] hover:shadow-lg mt-4`}
        >
          {isSubmitted ? "Submitted!" : displayTimer === 0 ? "Getting results..." : "Submit This Image"}
        </Button>
      )}
      {
        isSoloGame ?
      (
         <div className="mt-8 pt-6 border-t">
         <form onSubmit={handleFeedbackSubmit} className="space-y-4">
           <textarea
             className="w-full p-3 border rounded-lg resize-none h-24 focus:outline-none focus:ring-2 focus:ring-purple-500"
             placeholder="Help us improve! Share your thoughts about the game..."
             value={feedback}
             onChange={(e) => setFeedback(e.target.value)}
             disabled={isSubmitting}
           />
           <Button
             type="submit"
             disabled={isSubmitting || !feedback.trim()}
             className="w-full"
           >
             {isSubmitting ? 'Submitting...' : 'Submit Feedback'}
           </Button>
         </form>
       </div> 
      ):(<>
      </>)
      }
        {/* <Button
          onClick={() => handleSubmit(false)}
          disabled={isSubmitted || displayTimer === 0}
          className={`w-full h-12 text-base ${
            isSubmitted || displayTimer === 0
              ? 'bg-gray-100 text-gray-700 cursor-not-allowed' 
              : 'bg-gradient-to-r from-purple-600 to-blue-600 hover:from-purple-700 hover:to-blue-700'
          } shadow-md transform transition-all duration-300 hover:scale-[1.01] hover:shadow-lg mt-4`}
        >
          {isSubmitted ? "Submitted!" : displayTimer === 0 ? "Getting results..." : "Submit This Image"}
        </Button> */}
      </div>
      <ImageModal 
      isOpen={!!modalImage} 
      onClose={() => setModalImage(null)} 
      imageUrl={modalImage} 
    />
    </div>
  );
};

export default ImageSubmitScreen;