import React from 'react';

const ImageModal = ({ isOpen, onClose, imageUrl }) => {
  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black/70 z-50 flex flex-col items-center p-4"
      onClick={onClose}
    >
      <p className="text-white text-lg mb-4">Press and hold the image to save</p>
      <img 
        src={imageUrl} 
        alt="Download" 
        className="w-[95%] h-auto object-contain"
      />
    </div>
  );
};

export {ImageModal};
