import React, { useEffect, useState, useRef } from 'react';
import { collection, getDocs, updateDoc, doc, query, orderBy, where } from 'firebase/firestore';
import { firestore } from '../firebase/config';
import { Card } from "../components/ui/card";
import { Button } from "../components/ui/button";
import { Heart } from "lucide-react";
import { useToast } from '../components/ui/toastProvider';

const ImageCard = ({ image, onLike, hasLiked, className }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  
  return (
    <Card className={`
      group relative overflow-hidden
      ${className || ''} 
    `}>
      <div className={`w-full bg-gray-100 ${className || 'aspect-square'}`}>
        {!isLoaded && (
          <div className="absolute inset-0 animate-pulse bg-gray-200" />
        )}
        <img
          src={image.submittedImage.imageUrl}
          alt="Submitted fashion"
          loading="lazy"
          className={`
            object-cover w-full h-full transition-all duration-300 group-hover:scale-105
            ${isLoaded ? 'opacity-100' : 'opacity-0'}
          `}
          onLoad={() => setIsLoaded(true)}
        />
      </div>
      <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black/60 to-transparent">
        <div className="flex items-center justify-between">
          
          <Button
            variant={hasLiked ? "default" : "ghost"}
            size="icon"
            onClick={() => onLike(image.id)}
            className={`
              h-8 w-8 rounded-full bg-white/20 hover:bg-white/40
              ${hasLiked ? 'bg-red-500 hover:bg-red-600' : ''}
            `}
          >
            <Heart className={`h-4 w-4 ${hasLiked ? 'fill-white' : 'text-white'}`} />
          </Button>
        </div>
      </div>
      <div className="absolute top-2 right-2 px-2 py-1 bg-black/50 rounded text-white text-sm">
        {image.submittedImage.likes || 0} likes
      </div>
    </Card>
  );
};

const HallOfFameScreen = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visibleCount, setVisibleCount] = useState(12);
  const observerRef = useRef(null);
  const { showToast } = useToast();
  const deviceId = localStorage.getItem('deviceId');
  const [likedImages, setLikedImages] = useState(new Set());

  // 점수 계산 함수 - 좋아요 수와 최신성을 고려
  const calculateScore = (image) => {
    const likes = image.submittedImage.likes || 0;
    const ageInDays = (Date.now() - image.timestamp) / (1000 * 60 * 60 * 24);
    const recencyBonus = Math.max(0, 7 - ageInDays) * 0.5; // 7일 이내 게시물에 보너스
    
    return likes + recencyBonus;
  };

  useEffect(() => {
    const loadImages = async () => {
      try {
        const submissionLogsRef = collection(firestore, 'submissionLogs');
        const q = query(
          submissionLogsRef,
          where('selectedImageIndex', '>', 0), // 기본 이미지 제외
        );
        
        const querySnapshot = await getDocs(q);
        const imageData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          score: calculateScore(doc.data())
        }));

        // 좋아요 순으로 정렬하되, 최신 게시물도 고려
        const sortedImages = imageData.sort((a, b) => b.score - a.score);

        // 이전에 좋아요 한 이미지 로드
        const likedImagesFromStorage = JSON.parse(localStorage.getItem(`likedImages_${deviceId}`)) || [];
        setLikedImages(new Set(likedImagesFromStorage));
        
        setImages(sortedImages);
        setLoading(false);
      } catch (error) {
        console.error("Error loading images:", error);
        showToast('Failed to load images', 'error');
        setLoading(false);
      }
    };

    loadImages();
  }, [deviceId, showToast]);

  const handleLike = async (imageId) => {
    if (likedImages.has(imageId)) {
      showToast('You already liked this image', 'info');
      return;
    }

    try {
      const imageRef = doc(firestore, 'submissionLogs', imageId);
      const newLikes = (images.find(img => img.id === imageId)?.submittedImage.likes || 0) + 1;
      
      await updateDoc(imageRef, {
        'submittedImage.likes': newLikes
      });

      // 상태 업데이트
      setImages(prev => prev.map(img => 
        img.id === imageId 
          ? { ...img, submittedImage: { ...img.submittedImage, likes: newLikes } }
          : img
      ));

      // 좋아요 기록 저장
      const newLikedImages = new Set(likedImages).add(imageId);
      setLikedImages(newLikedImages);
      localStorage.setItem(`likedImages_${deviceId}`, JSON.stringify([...newLikedImages]));

      showToast('Liked!', 'success');
    } catch (error) {
      console.error("Error updating likes:", error);
      showToast('Failed to like image', 'error');
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting && visibleCount < images.length) {
          setVisibleCount(prev => Math.min(prev + 8, images.length));
        }
      },
      { threshold: 0.1 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => observer.disconnect();
  }, [visibleCount, images.length]);

  if (loading) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="animate-pulse space-y-4">
          <div className="h-8 bg-gray-200 rounded w-48 mx-auto" />
          <div className="grid grid-cols-2 gap-4">
            {[...Array(8)].map((_, i) => (
              <div key={i} className="aspect-[2/3] bg-gray-200 rounded-lg" />
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 max-w-[480px]">
      <h1 className="text-2xl font-bold text-center mb-8">Hall of Fame</h1>

      <div className="grid grid-cols-2 gap-4">
        {images.slice(0, visibleCount).map((image) => (
          <ImageCard
            key={image.id}
            image={image}
            onLike={handleLike}
            hasLiked={likedImages.has(image.id)}
            className="aspect-[2/3]"
          />
        ))}
      </div>

      {visibleCount < images.length && (
        <div 
          ref={observerRef}
          className="h-20 flex items-center justify-center mt-4"
        >
          <div className="animate-pulse">Loading more...</div>
        </div>
      )}
    </div>
  );
};

export default HallOfFameScreen;