import React, { useEffect, useRef, useState } from 'react';

const Slider = ({
  value = [0],
  onValueChange,
  min = 0,
  max = 100,
  step = 1,
  className = ''
}) => {
  const sliderRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);

  const getPercentage = (value) => {
    return ((value - min) / (max - min)) * 100;
  };

  const getValue = (percentage) => {
    const rawValue = ((percentage / 100) * (max - min)) + min;
    const steppedValue = Math.round(rawValue / step) * step;
    return Math.min(max, Math.max(min, steppedValue));
  };

  const handleMove = (clientX) => {
    if (!sliderRef.current) return;
    const rect = sliderRef.current.getBoundingClientRect();
    const percentage = ((clientX - rect.left) / rect.width) * 100;
    const newValue = getValue(percentage);
    if (newValue !== value[0]) {
      onValueChange([newValue]);
    }
  };

  const handleMouseDown = (e) => {
    e.preventDefault(); // Prevent text selection
    setIsDragging(true);
    handleMove(e.clientX);
  };

  const handleTouchStart = (e) => {
    e.preventDefault(); // Prevent scrolling while dragging
    setIsDragging(true);
    handleMove(e.touches[0].clientX);
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (isDragging) {
        e.preventDefault();
        handleMove(e.clientX);
      }
    };

    const handleTouchMove = (e) => {
      if (isDragging) {
        e.preventDefault();
        handleMove(e.touches[0].clientX);
      }
    };

    const handleEnd = () => {
      setIsDragging(false);
    };

    // Mouse events
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleEnd);

    // Touch events
    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    document.addEventListener('touchend', handleEnd);
    document.addEventListener('touchcancel', handleEnd);

    return () => {
      // Cleanup mouse events
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleEnd);

      // Cleanup touch events
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleEnd);
      document.removeEventListener('touchcancel', handleEnd);
    };
  }, [isDragging]);

  return (
    <div
      ref={sliderRef}
      className={`relative w-full h-5 flex items-center cursor-pointer ${className}`}
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
    >
      {/* Track background */}
      <div className="absolute w-full h-2 bg-gray-200 rounded-full" />
      
      {/* Filled track */}
      <div
        className="absolute h-2 bg-purple-600 rounded-full"
        style={{ width: `${getPercentage(value[0])}%` }}
      />
      
      {/* Thumb */}
      <div
        className="absolute w-5 h-5 bg-white border-2 border-purple-600 rounded-full shadow-md
          transform -translate-x-1/2 transition-shadow hover:shadow-lg touch-none"
        style={{ left: `${getPercentage(value[0])}%` }}
      />
    </div>
  );
};

export { Slider };