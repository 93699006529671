import { ref, set, push, get, query, orderByChild, equalTo } from 'firebase/database';
import { db, serverTimestamp } from './config';

export const createGame = async () => {
  const gamesRef = ref(db, 'games');
  const newGameRef = push(gamesRef);
  
  await set(newGameRef, {
    status: 'waiting',
    createdAt: serverTimestamp(),
    maxPlayers: 4,
    players: {},
    phase: 'waiting'
  });

  return newGameRef.key;
};

export const joinGame = async () => {
  // DeviceInfo 대신 브라우저의 userAgent + 타임스탬프로 고유 ID 생성
  const deviceId = window.navigator.userAgent + Date.now();
  
  const gamesRef = ref(db, 'games');
  const snapshot = await get(query(gamesRef, 
    orderByChild('status'), 
    equalTo('waiting')
  ));
  
  const games = snapshot.val() || {};
  const [latestGameId, latestGame] = Object.entries(games)
    .sort((a, b) => b[1].createdAt - a[1].createdAt)[0] || [];
    
  if (latestGameId) {
    await set(ref(db, `games/${latestGameId}/players/${deviceId}`), {
      ready: false,
      submittedImage: null
    });
    
    return {
      gameId: latestGameId,
      deviceId
    };
  }
  
  return null;
};

// 새로운 유틸리티 함수 추가
export const generateDeviceId = () => {
  return 'user_' + Math.random().toString(36).substr(2, 9);
};