import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db,firestore } from '../firebase/config';
import { Card } from "../components/ui/card";
import { Button } from "../components/ui/button";
import { ArrowLeft } from "lucide-react";
import { useToast } from '../components/ui/toastProvider';
import { ref, onValue, set, get } from 'firebase/database';


const CACHE_KEY_PREFIX = 'images_cache_';
const CACHE_EXPIRY = 24 * 60 * 60 * 1000;

const getCachedUrls = (folder) => {
  const cached = localStorage.getItem(`${CACHE_KEY_PREFIX}${folder}`);
  if (!cached) return null;

  const { timestamp, images } = JSON.parse(cached);
  if (Date.now() - timestamp > CACHE_EXPIRY) {
    localStorage.removeItem(`${CACHE_KEY_PREFIX}${folder}`);
    return null;
  }
  return images;
};

const setCachedUrls = (folder, images) => {
  const cacheData = {
    timestamp: Date.now(),
    images
  };
  localStorage.setItem(`${CACHE_KEY_PREFIX}${folder}`, JSON.stringify(cacheData));
};

const ImageCard = ({ image, onSelect, isSelected, className }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <Card
      className={`
        group relative overflow-hidden cursor-pointer transition-all duration-200
        ${isSelected ? 'ring-2 ring-primary' : 'hover:ring-2 hover:ring-primary/50'}
        ${className || ''} 
      `}
      onClick={() => {
        onSelect(image);
      }}
    >
      <div className={`w-full bg-gray-100 ${className || 'aspect-square'}`}>
        {!isLoaded && (
          <div className="absolute inset-0 animate-pulse bg-gray-200" />
        )}
        <img
          src={image.imageUrl}
          alt={`${image.fileName}`}
          loading="lazy"
          className={`
            object-cover w-full h-full transition-all duration-300 group-hover:scale-105
            ${isLoaded ? 'opacity-100' : 'opacity-0'}
          `}
          onLoad={() => setIsLoaded(true)}
        />
      </div>
    </Card>
  );
};

const ImageSelectionScreen = () => {
  const navigate = useNavigate();
  const { gameId } = useParams();
  const { theme, folder, index, timer, selectedImages: initialSelectedImages, startTime } = useLocation().state || {};
  const isNavigating = useRef(false);
  const [selectedImages, setSelectedImages] = useState(initialSelectedImages || []);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visibleCount, setVisibleCount] = useState(12);
  const observerRef = useRef(null);
  const { showToast } = useToast();
  const [deviceId] = useState(localStorage.getItem('deviceId'));
  const previousLengthRef = useRef(null);  // 여기에 ref 추가
  const setupComplete = useRef(false); 

  useEffect(() => {
    const playerImagesRef = ref(db, `games/${gameId}/players/${deviceId}/generatedImages`);
    
    // 한 번만 실행되는 초기 설정
    if (!setupComplete.current) {
      get(playerImagesRef).then((snapshot) => {
        if (snapshot.exists()) {
          previousLengthRef.current = snapshot.val().length;
          setupComplete.current = true;
        }
      });
    }
    
    const unsubscribe = onValue(playerImagesRef, (snapshot) => {
      if (snapshot.exists() && setupComplete.current) {  // 초기 설정이 완료된 경우에만
        const images = snapshot.val();
        if (images.length > previousLengthRef.current) {
          showToast('New image generated! Check your gallery', 'success');
        }
        previousLengthRef.current = images.length;
      }
    });

    return () => {
      unsubscribe();
      // cleanup 시 초기화
      setupComplete.current = false;
      previousLengthRef.current = null;
    };
  }, [gameId, deviceId, showToast]);

  useEffect(() => {
    if (timer <= 0 && !isNavigating.current) {
      isNavigating.current = true;
      navigate(`/image-submit/${gameId}`);
    }
  }, [timer, navigate, gameId]);

  useEffect(() => {
    const loadImages = async () => {
      try {
        const cachedUrls = getCachedUrls(folder);
        
        if (cachedUrls?.length > 1) {
          setImages(cachedUrls);
          setLoading(false);
          return;
        }
        
        const imagesRef = collection(firestore, folder);
         const querySnapshot = await getDocs(imagesRef);
         const imageData = querySnapshot.docs.map(doc => {
           const data = doc.data();
           return {
             id: doc.id,
             fileName: data.fileName,
             imageUrl: data.imageUrl,
             prompt: data.prompt,
             createdAt: data.createdAt
           };
         });
     
        setCachedUrls(folder, imageData);
        setImages(imageData);  // 여기서 한번만 set
        setLoading(false);
      } catch (error) {
        console.error("Error loading images:", error);
        setLoading(false);
      }
     };

    if (folder) {
      loadImages();
    }
  }, [folder]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting && visibleCount < images.length) {
          setVisibleCount(prev => Math.min(prev + 8, images.length));
        }
      },
      { threshold: 0.1 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => observer.disconnect();
  }, [visibleCount, images.length]);

  const handleImageSelect = (image) => {
    if (!isNavigating.current) {
      const updatedSelectedImages = [...selectedImages];
      updatedSelectedImages[index] = image;  // 전체 이미지 객체 저장
      // updatedSelectedImages[index] = { imageUrl: image.imageUrl };
      setSelectedImages(updatedSelectedImages);
      isNavigating.current = true;
      navigate(`/mixer/${gameId}`, {
        state: { 
          selectedImages: updatedSelectedImages,  // 전체 배열 전달
          selectedImageData: image,
          index,
          timer,
          startTime 
        }
      });
    }
  };

  const handleBack = () => {
    if (!isNavigating.current) {
      isNavigating.current = true;
      navigate(`/mixer/${gameId}`, { 
        state: { timer, selectedImages, startTime } 
      });
    }
  };

  if (loading && images.length === 0) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="animate-pulse space-y-4">
          <div className="h-8 bg-gray-200 rounded w-48 mx-auto" />
          <div className="grid grid-cols-2 gap-4">
            {[...Array(8)].map((_, i) => (
              <div key={i} className="aspect-square bg-gray-200 rounded-lg" />
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 max-w-[480px]">
      <div className="flex items-center mb-6">
        <Button 
          variant="ghost" 
          size="icon"
          onClick={handleBack}
          className="mr-4"
        >
          <ArrowLeft className="h-6 w-6" />
        </Button>
        <h1 className="text-2xl font-bold">{theme}</h1>
      </div>

      <div className="grid grid-cols-2 gap-4">
        {images.slice(0, visibleCount).map((image) => (
          <ImageCard
            key={image.id}
            image={image}
            onSelect={handleImageSelect}
            isSelected={selectedImages[index]?.imageUrl === image.imageUrl}
            className="aspect-[2/3]"
          />
        ))}
      </div>

      {visibleCount < images.length && (
        <div 
          ref={observerRef}
          className="h-20 flex items-center justify-center mt-4"
        >
          <div className="animate-pulse">Loading more...</div>
        </div>
      )}
    </div>
  );
};

export default ImageSelectionScreen;