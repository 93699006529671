import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ref as dbRef, onValue, get } from 'firebase/database';
import { ref as storageRef, getDownloadURL } from 'firebase/storage';
import { db, firestore, storage } from '../firebase/config';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { Button } from "../components/ui/button";
import { ImageModal } from "../components/ui/imageModal";
import { Trophy, Download, Crown, Medal, Award } from "lucide-react";

const ResultScreen = () => {
  const { gameId } = useParams();
  const navigate = useNavigate();
  const [rankings, setRankings] = useState([]);
  const [myImage, setMyImage] = useState(null);
  const [myRank, setMyRank] = useState(null);
  const [feedback, setFeedback] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const deviceId = useMemo(() => localStorage.getItem('deviceId'), []);
  const isNavigating = useRef(false);
  const resultsLogged = useRef(false);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const handleFeedbackSubmit = async (e) => {
    e.preventDefault();
    if (!feedback.trim()) return;
    
    setIsSubmitting(true);
    try {
      const feedbacksRef = collection(firestore, 'feedbacks');
      await addDoc(feedbacksRef, {
        gameId,
        feedback: feedback.trim(),
        deviceId,
        timestamp: serverTimestamp()
      });
      
      setFeedback('');
      // Optional: Show success message
      alert('Thank you for your feedback!');
    } catch (error) {
      console.error('Error submitting feedback:', error);
      alert('Failed to submit feedback. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const calculateScores = (game) => {
    if (!game?.players || !game?.ratings) return [];

    const playerScores = {};
    
    // Initialize scores for all players
    Object.keys(game.players).forEach(playerId => {
      if (game.players[playerId]?.submittedImage) {
        playerScores[playerId] = {
          totalPoints: 0,
          votes: 0,
          imageUrl: game.players[playerId].submittedImage?.imageUrl,
          mixInfo: game.players[playerId].submittedImage?.mixInfo
        };
      }
    });

    // Calculate points only if we have valid player scores
    if (Object.keys(playerScores).length > 0) {
      Object.entries(game.ratings).forEach(([voterId, votes]) => {
        if (votes) {
          Object.entries(votes).forEach(([playerId, rating]) => {
            if (playerScores[playerId]) {
              // 별점을 그대로 점수로 사용
              playerScores[playerId].totalPoints += rating;
              playerScores[playerId].votes += 1;
            }
          });
        }
      });
    }

    return Object.entries(playerScores)
      .map(([playerId, data]) => ({
        playerId,
        isMe: playerId === deviceId,
        imageUrl: data.imageUrl,
        mixInfo: data.mixInfo,
        totalPoints: data.totalPoints,
        averagePoints: data.votes > 0 ? data.totalPoints / data.votes : 0,
        totalVotes: data.votes
      }))
      .sort((a, b) => b.totalPoints - a.totalPoints);
  };

  useEffect(() => {
    const gameRef = dbRef(db, `games/${gameId}`);
    const unsubscribe = onValue(gameRef, async (snapshot) => {
      const game = snapshot.val();
      if (!game) return;

      const sortedRankings = calculateScores(game);
      setRankings(sortedRankings);

      // Set my rank and image if rankings exist
      if (sortedRankings.length > 0) {
        const myRankIndex = sortedRankings.findIndex(player => player.isMe);
        if (myRankIndex !== -1) {
          setMyRank(myRankIndex + 1);
          setMyImage(sortedRankings[myRankIndex].imageUrl);
        }

        // Log final results to Firestore (only once)
        if (!resultsLogged.current) {
          resultsLogged.current = true;
          
          try {
            const gameResultsRef = collection(firestore, 'gameResults');
            await addDoc(gameResultsRef, {
              gameId,
              timestamp: serverTimestamp(),
              rankings: sortedRankings.map(player => ({
                playerId: player.playerId,
                rank: sortedRankings.findIndex(p => p.playerId === player.playerId) + 1,
                totalPoints: player.totalPoints,
                averagePoints: player.averagePoints,
                totalVotes: player.totalVotes,
                imageUrl: player.imageUrl,
                mixInfo: player.mixInfo
              })),
              totalPlayers: sortedRankings.length,
              winningScore: sortedRankings[0]?.totalPoints || 0
            });
          } catch (error) {
            console.error('Error logging game results:', error);
          }
        }
      }
    });

    return () => unsubscribe();
  }, [gameId, deviceId]);

  const getRankIcon = (rank) => {
    switch(rank) {
      case 1: return <Crown className="w-8 h-8 text-yellow-500" />;
      case 2: return <Medal className="w-8 h-8 text-gray-400" />;
      case 3: return <Award className="w-8 h-8 text-amber-600" />;
      default: return <Trophy className="w-8 h-8 text-purple-400" />;
    }
  };

  const createCompositeImage = async (submittedImagePath, sourceImagesPaths) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
  
    const aspectRatio = 768/512;
  const mainImageWidth = 1024;
  const mainImageHeight = mainImageWidth * aspectRatio; // 768
  const sideImagesWidth = mainImageWidth / 3; // 170.67 (512/3)
  const canvasWidth = mainImageWidth + sideImagesWidth;
    
    canvas.width = canvasWidth;
    canvas.height = mainImageHeight;
  
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvasWidth, mainImageHeight);
  
    try {
      // 메인 이미지
      const mainImg = await loadImage(submittedImagePath);
      ctx.drawImage(mainImg, 0, 0, mainImageWidth, mainImageHeight);

  
      // 소스 이미지들 설정
      const sourceImagesArray = [
        sourceImagesPaths.clothing,
        sourceImagesPaths.baseModel,
        sourceImagesPaths.background
      ];
  
      const sourceImageHeight = mainImageHeight / 3;
      
      // 소스 이미지들 그리기
      for (let i = 0; i < sourceImagesArray.length; i++) {
        const img = await loadImage(sourceImagesArray[i]);
        const y = i * sourceImageHeight;
        
        ctx.drawImage(
          img,
          mainImageWidth,
          y,
          sideImagesWidth,
          sourceImageHeight
        );
      }
  
      return new Promise((resolve, reject) => {
        try {
          canvas.toBlob(blob => {
            if (blob) {
              resolve(URL.createObjectURL(blob));
            } else {
              reject(new Error('Failed to create blob'));
            }
          }, 'image/jpeg', 0.95);
        } catch (error) {
          reject(error);
        }
      });
    } catch (error) {
      console.error('Error creating composite image:', error);
      throw error;
    }
  };
  
  // Helper function to load image
  const loadImage = async (imagePath) => {
    try {
      // Firebase Storage 경로로 부터 다운로드 URL을 가져옴
      const imageRef = storageRef(storage, imagePath);
      const url = await getDownloadURL(imageRef);
      
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "anonymous";  // crossOrigin 설정 추가
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = url;
      });
    } catch (error) {
      console.error('Error loading image:', error);
      throw error;
    }
  };

  const handleSaveImage = async () => {
    try {
      const submittedImageRef = dbRef(db, `games/${gameId}/players/${deviceId}/submittedImage`);
      const snapshot = await get(submittedImageRef);
      
      if (snapshot.exists()) {
        const submittedImage = snapshot.val();
        
        // Firebase Storage 경로에서 이미지 URL 가져오기
        const mainImageRef = storageRef(storage, submittedImage.imageUrl);
        const mainImageUrl = await getDownloadURL(mainImageRef);
        
        // sourceImages의 URL들도 Firebase Storage URL로 변환
        const sourceImagesUrls = {
          clothing: await getDownloadURL(storageRef(storage, submittedImage.mixInfo.sourceImages.clothing)),
          baseModel: await getDownloadURL(storageRef(storage, submittedImage.mixInfo.sourceImages.baseModel)),
          background: await getDownloadURL(storageRef(storage, submittedImage.mixInfo.sourceImages.background))
        };

        const compositeImageUrl = await createCompositeImage(
          mainImageUrl,
          sourceImagesUrls
        );
  
        if (isMobile) {
          setModalImage(compositeImageUrl);
        } else {
          // 기존 PC용 다운로드 코드
          const link = document.createElement('a');
          link.href = compositeImageUrl;
          link.download = 'my-creation-with-sources.jpg';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(compositeImageUrl);
        }
      }
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

  const handleNewGame = () => {
    if (!isNavigating.current) {
      isNavigating.current = true;
      navigate('/');
    }
  };

  return (
    <div className="min-h-screen bg-white py-4 px-4">
      <div className="max-w-6xl mx-auto space-y-6">
        {myRank && (
          <div className="text-center space-y-2">
            <h1 className="text-2xl font-bold text-gray-900">
              You placed #{myRank} of {rankings.length}
            </h1>
            <p className="text-gray-600">
              Total Points: {rankings.find(p => p.isMe)?.totalPoints || 0}
            </p>
          </div>
        )}

        <div className="grid gap-4">
          {rankings.map((player, index) => (
            <div
              key={player.playerId}
              className={`p-4 rounded-lg ${
                player.isMe ? 'bg-purple-50 border-2 border-purple-200' : 'bg-gray-50'
              }`}
            >
              <div className="flex items-center gap-4">
                <div className="flex-shrink-0">
                  {getRankIcon(index + 1)}
                </div>
                
                <div className="flex-1 min-w-0">
                  <div className="flex items-center gap-2">
                    <span className="text-lg font-semibold">
                      #{index + 1}
                    </span>
                    {player.isMe && (
                      <span className="text-sm bg-purple-100 text-purple-700 px-2 py-0.5 rounded">
                        You
                      </span>
                    )}
                  </div>
                  <div className="text-sm text-gray-600">
                    {player.totalPoints} points ({player.totalVotes} votes)
                  </div>
                </div>

                <div className="flex-shrink-0 w-20 aspect-[512/768]">
                  <img
                    src={player.imageUrl}
                    alt={`Rank ${index + 1}`}
                    className="w-full h-full object-cover rounded"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="flex gap-2 pt-4">
          {myImage && (
            <Button
              onClick={handleSaveImage}
              className="flex-1"
              variant="outline"
            >
              <Download className="w-4 h-4 mr-2" />
              Save My Creation
            </Button>
          )}
          
          <Button
            onClick={handleNewGame}
            className="flex-1"
          >
            Play Again
          </Button>
        </div>

        {/* Feedback Section */}
        <div className="mt-8 pt-6 border-t">
          <form onSubmit={handleFeedbackSubmit} className="space-y-4">
            <textarea
              className="w-full p-3 border rounded-lg resize-none h-24 focus:outline-none focus:ring-2 focus:ring-purple-500"
              placeholder="Help us improve! Share your thoughts about the game..."
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              disabled={isSubmitting}
            />
            <Button
              type="submit"
              disabled={isSubmitting || !feedback.trim()}
              className="w-full"
            >
              {isSubmitting ? 'Submitting...' : 'Submit Feedback'}
            </Button>
          </form>
        </div>
      </div>
      <ImageModal 
        isOpen={!!modalImage} 
        onClose={() => setModalImage(null)} 
        imageUrl={modalImage} 
      />
    </div>
  );
};

export default ResultScreen;