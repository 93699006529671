import React from 'react';
import { ref, push, set, serverTimestamp } from 'firebase/database';
import { collection, getDocs } from 'firebase/firestore';
import { db, firestore } from '../firebase/config';
import { generateDeviceId } from '../firebase/gameLogic';
import { useNavigate } from 'react-router-dom';
import frontScreenImage from '../images/frontScreen.png';

const MainMenuScreen = () => {
  const navigate = useNavigate();
  
  const startSoloGame = async () => {
    const deviceId = localStorage.getItem('deviceId') || generateDeviceId();
    localStorage.setItem('deviceId', deviceId);
    
    const modelCollectionRef = collection(firestore, 'model');
    const querySnapshot = await getDocs(modelCollectionRef);
    
    if (querySnapshot.empty) {
      // 에러 처리는 실제 UI에 맞게 수정하세요
      console.error('No models available');
      return;
    }
  
    const documents = querySnapshot.docs;
    const randomIndex = Math.floor(Math.random() * documents.length);
    const randomDoc = documents[randomIndex];
    const docData = randomDoc.data();
  
    const initialImage = {
      imageUrl: docData.imageUrl,
      filename: docData.fileName,
      theme: docData.theme,
      mixInfo: { 
        type: 'initial',
        filename: docData.fileName,
        prompt: docData.prompt 
      },
      resources: {
        model: randomDoc.id
      }
    };
  
    const newGameRef = push(ref(db, 'games'));
    fetch(process.env.REACT_APP_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ check_type: 'container_status' })
    }).catch(console.error);
    
    await set(newGameRef, {
      status: 'playing',  // 바로 playing 상태로 시작
      isSoloGame: true,   // solo 게임 식별자
      startTime: Date.now(),
      createdAt: serverTimestamp(),
      initialImage,
      players: {
        [deviceId]: { 
          ready: true,   // 자동으로 ready 상태
          joinedAt: serverTimestamp() 
        }
      }
    });
  
    navigate(`/mixer/${newGameRef.key}`);
  };

  const startFriendsGame = () => {
    navigate('/friends-game');
  };

  const viewHallOfFame = () => {
    navigate('/hall-of-fame');
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex flex-col justify-center mb-6">
        <div className="text-center mb-3 text-gray-600">
          Currently in beta. invite-only.
        </div>
        <img 
          src={frontScreenImage} 
          alt="Front Screen Fashion"
          className="w-full max-w-3xl rounded-lg shadow-lg mx-auto"
        />
      </div>
      <div className="max-w-md mx-auto space-y-4">
      <style>
          {`
            @import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@700&display=swap');
            .title-text {
              font-family: 'Space Grotesk', sans-serif;
            }
          `}
        </style>
        <div>
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-8 title-text">
          aesthetic core
        </h2>
        </div>
     
        
        <button
          onClick={startSoloGame}
             className="menu-button w-full py-4 text-gray-800 rounded-lg font-bold tracking-wider text-sm uppercase transition-all duration-300 hover:scale-105"
        >
          Play Solo
        </button>
        
        <button
          onClick={startFriendsGame}
            className="menu-button w-full py-4 text-gray-800 rounded-lg font-bold tracking-wider text-sm uppercase transition-all duration-300 hover:scale-105"
        >
          Play with Friends
        </button>
        
        {/* <button
          onClick={viewHallOfFame}
          className="w-full py-4 bg-gradient-to-r from-amber-500 to-orange-500 hover:from-amber-600 hover:to-orange-600
                   text-white rounded-lg font-bold shadow-md transform transition-all duration-300
                   hover:scale-105 hover:shadow-lg"
        >
          Hall of Fame
        </button> */}
      </div>
    </div>
  );
};

export default MainMenuScreen;