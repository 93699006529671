import React from 'react';
import { Button } from "./button";
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from './card';

const HowToPlayModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/70 z-50 flex items-center justify-center p-4">
      <Card className="w-full max-w-sm">
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-center">How to Play</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4 text-gray-600 text-center">
          <ol className="list-decimal list-inside space-y-2">
            <li>Pick 3 images</li>
            <li>Touch the Mix button</li>
            <li>Repeat</li>
          </ol>
        </CardContent>
        <CardFooter className="flex justify-center">
          <Button onClick={onClose}>Let's Start Creating!</Button>
        </CardFooter>
      </Card>
    </div>
  );
};

export default HowToPlayModal;