import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MixerScreen from './screens/MixerScreen';
import ImageSelectionScreen from './screens/ImageSelectionScreen';
import ImageSubmitScreen from './screens/ImageSubmitScreen';
import RatingScreen from './screens/RatingScreen';
import ResultScreen from './screens/ResultScreen';
import WaitingRoomScreen from './screens/WaitingRoomScreen';
import FriendsGameScreen from './screens/FriendsGameScreen';
import PrivateWaitingRoom from './screens/PrivateWaitingRoomScreen';
import MainMenuScreen from './screens/MainMenuScreen';
import './styles/globals.css';
import { ToastProvider } from './components/ui/toastProvider';
import HallOfFameScreen from './screens/HallOfFameScreen';

export default function App() {
  return (
    <Router>
      <ToastProvider>
      <div className="min-h-screen bg-white">
        <Routes>
          <Route path="/" element={<MainMenuScreen />} />
          <Route path="/friends-game" element={<FriendsGameScreen />} />
          <Route path="/waiting-room/:gameId" element={<PrivateWaitingRoom />} /> 
          <Route path="/hall-of-fame" element={<HallOfFameScreen />} />
          {/* <Route path="/" element={<WaitingRoomScreen />} /> */}
          <Route path="/mixer/:gameId" element={<MixerScreen />} />
          <Route path="/image-selection/:gameId" element={<ImageSelectionScreen />} />
          <Route path="/image-submit/:gameId" element={<ImageSubmitScreen />} />
          <Route path="/rating/:gameId" element={<RatingScreen />} />
          <Route path="/result/:gameId" element={<ResultScreen />} /> 
        </Routes>
      </div>
      </ToastProvider>
    </Router>
  );
}