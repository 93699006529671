import { useParams, useNavigate, useLocation } from 'react-router-dom';
import React, { useEffect, useState, useRef } from 'react';
import { ref, runTransaction, onValue, set, get, serverTimestamp, onDisconnect } from 'firebase/database';
import { collection, getDocs } from 'firebase/firestore';
import { db, firestore } from '../firebase/config';
import { generateDeviceId } from '../firebase/gameLogic';


const PrivateWaitingRoom = () => {
  const { gameId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [players, setPlayers] = useState({});
  const [roomCode, setRoomCode] = useState(location.state?.roomCode || '');
  const [deviceId, setDeviceId] = useState(localStorage.getItem('deviceId') || null);
  const isHost = location.state?.isHost;
  const [error, setError] = useState('');
  const isNavigating = useRef(false);

  useEffect(() => {
    if (!deviceId) {
      const newDeviceId = generateDeviceId();
      localStorage.setItem('deviceId', newDeviceId);
      setDeviceId(newDeviceId);
    }
  }, [deviceId]);



  useEffect(() => {
    if (!deviceId || !gameId) return;

    const gameRef = ref(db, `games/${gameId}`);
    const playerRef = ref(db, `games/${gameId}/players/${deviceId}`);

    const checkAndCleanRoom = async () => {
      const snapshot = await get(gameRef);
      const game = snapshot.val();
      if (game && Object.keys(game.players || {}).length === 0) {
        await set(gameRef, null); // 방 삭제
      }
    };
    set(playerRef, {
      ready: false,
      joinedAt: serverTimestamp()
    });
  
    // 2. 연결 해제 시 플레이어만 제거
    onDisconnect(playerRef).remove();
    
    const unsubscribe = onValue(gameRef, async (snapshot) => {
      const game = snapshot.val();
      if (!game) {
        navigate('/friends-game');
        return;
      }
      
      setPlayers(game.players || {});
      setRoomCode(game.roomCode);
      
      if (game.status === 'playing') {
        if (game.players[deviceId]?.ready) {
          navigate(`/mixer/${gameId}`);
        } else {
          navigate('/friends-game');
        }
      }
    });

    return () => {
      unsubscribe();
      if (gameId && deviceId) {
        set(playerRef, null).then(checkAndCleanRoom);
      }
    };
  }, [deviceId, gameId, navigate]);

 

  const toggleReady = async () => {
    if (!deviceId || !gameId) return;
    const playerRef = ref(db, `games/${gameId}/players/${deviceId}/ready`);
    const snapshot = await get(playerRef);
    await set(playerRef, !snapshot.val());
  };


  const startGame = async () => {
    if (!deviceId || !gameId) return;
    if (isNavigating.current) return;
  
    try {
      const gameRef = ref(db, `games/${gameId}`);
      const snapshot = await get(gameRef);
      const game = snapshot.val();
  
      const readyPlayers = Object.entries(game.players)
        .filter(([_, p]) => p.ready)
        .reduce((acc, [id, p]) => ({ ...acc, [id]: p }), {});
      if (Object.keys(readyPlayers).length >= 2) {
        isNavigating.current = true;
        // initialImage 설정
        const modelCollectionRef = collection(firestore, 'model');
        const querySnapshot = await getDocs(modelCollectionRef);
        if (!querySnapshot.empty) {
          const documents = querySnapshot.docs;
          const randomIndex = Math.floor(Math.random() * documents.length);
          const randomDoc = documents[randomIndex];
          const docData = randomDoc.data();
          
          const initialImage = {
            imageUrl: docData.imageUrl,
            filename: docData.fileName,
            theme: docData.theme,
            mixInfo: { 
              type: 'initial', 
              filename: docData.fileName,
              prompt: docData.prompt 
            },
            resources: {
              model: randomDoc.id  // 이 model ID가 vtonImages의 resources.model과 매칭됨
            }
          };

          fetch(process.env.REACT_APP_API_URL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ check_type: 'container_status' })
          }).catch(console.error); // 에러가 나도 계속 진행
          
          const startTimeStamp = Date.now(); 
          await runTransaction(gameRef, (currentData) => {
            if (currentData === null) {
              return {
                status: 'playing',
                startTime: startTimeStamp,
                players: readyPlayers,
                initialImage
              };
            }
            
            // 이미 데이터가 있는 경우
            if (!currentData.initialImage) {
              return {
                ...currentData,
                status: 'playing',
                startTime: startTimeStamp,
                players: readyPlayers,
                initialImage
              };
            }
            
            return {
              ...currentData,
              status: 'playing',
              startTime: startTimeStamp,
              players: readyPlayers
            };
          });
        }
      }
    } catch (error) {
      console.error('Error:', error);
      isNavigating.current = false;
    } finally {
      isNavigating.current = false;  // 성공/실패 상관없이 항상 리셋
    }
  };


  const readyCount = Object.values(players).filter(p => p.ready).length;
  const isReady = players[deviceId]?.ready;
  const canStartGame = readyCount >= 2 && isReady && isHost;

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-md mx-auto space-y-6">
        <div className="text-center space-y-2">
          <h1 className="text-2xl font-bold text-gray-800">Waiting Room</h1>
          <div className="text-lg font-mono bg-gray-100 py-2 rounded">
            Room Code: {roomCode}
          </div>
          <p className="text-sm text-gray-600">
            Share this code with your friends to join!
          </p>
        </div>

        <div className="space-y-4">
          {Object.entries(players).map(([id, player]) => (
            <div key={id} 
                 className={`flex justify-between items-center p-4 rounded-lg border
                           ${id === deviceId ? 'border-blue-500 bg-blue-50' : 'border-gray-200'}`}>
              <span className="font-medium">
                {id === deviceId ? 'You' : `Player ${id.slice(0, 4)}`}
                {id === Object.keys(players)[0] && ' (Host)'}
              </span>
              <span className={`font-bold ${player.ready ? 'text-green-500' : 'text-gray-500'}`}>
                {player.ready ? 'Ready' : 'Waiting'}
              </span>
            </div>
          ))}
        </div>

        {error && (
          <div className="text-red-500 text-center text-sm">
            {error}
          </div>
        )}

        <div className="space-y-4">
          <div className="text-center text-sm text-gray-600">
            Players: {Object.keys(players).length}
          </div>
          
          <button 
            onClick={canStartGame ? startGame : toggleReady}
            className={`w-full py-4 rounded-lg font-bold text-white
              ${canStartGame 
                ? 'bg-yellow-500 hover:bg-yellow-600' 
                : (isReady 
                    ? 'bg-green-500 hover:bg-green-600' 
                    : 'bg-blue-500 hover:bg-blue-600')}`}
          >
            {canStartGame ? 'Start Game!' : (isReady ? 'Cancel Ready' : 'Ready')}
          </button>

          <button
            onClick={() => navigate('/friends-game')}
            className="w-full py-2 text-gray-500 hover:text-gray-700"
          >
            Leave Room
          </button>
        </div>
      </div>
    </div>
  );
};

export default PrivateWaitingRoom;